.view-application-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view-application-details-left {
  width: calc(70% - 5px);
  font-size: $subtitle;
  color: $field-color;
  > div {
    flex-direction: column;
    padding: 30px;
  }
}

.view-application-details-right {
  width: calc(30% - 5px);
  > div {
    padding: 20px;
  }
}

.view-application-accordion {
  width: 100%;

  .accordion-body-container {
    background-color: white;
  }

  .accordion-item-header-container {
    height: 32px;
  }
}

.common-accordion-item-content-box {
  margin-bottom: 10px;
  padding: 5px 15px 10px 15px;
  font-size: $text;
  border: 1px solid $border-color;
  border-radius: 5px;

  .note-title,
  .alert-title,
  .document-title {
    max-width: 70%;
    font: $subtitle $font-medium;
    color: $secondary-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu-name {
    font: $subtitle !important;
  }
}

.common-accordion-item-content-box:last-child {
  margin-bottom: 0;
}

.date-owner-row,
.date-time-row {
  display: grid;
  grid-template-columns: 30px 1fr 40px 1fr;
  gap: 0 5px;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .title {
    max-width: 40px;
    color: $field-color;
  }

  .details {
    color: $primary-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.note-owner-name {
  color: $primary-color;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.report-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3px 5px;

  .title {
    color: $field-color;
    white-space: nowrap;
  }

  .details {
    color: $primary-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.note-title-row,
.alert-title-row,
.document-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .material-icons-round {
    width: 10px;
    cursor: pointer;
  }
  .material-icons-round:hover {
    color: $field-color;
  }
  .font-danger.material-icons-round:hover {
    color: red;
  }

  .download-icon:hover {
    color: #26466D;
  }
}

.document-title-row .document-title {
  color: $primary-color;
}

.view-application-document-action-buttons {
  display: flex;
  align-items: center;

  .font-danger {
    width: 24px;
  }
}

.download-icon {
  margin-right: 20px;
  color: $primary-color;
  cursor: pointer;
}

.application-status-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-side-status {
  display: flex;
  color: $primary-color;
}

.application-status {
  padding: 7px 10px;
  color: white;
  border-radius: 5px;
}

.approved-application-status {
  background-color: $success-color;
}

.declined-application-status {
  background-color: $error-color;
}

.view-application-status {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .react-select-container {
    min-width: 180px;
  }

  .ui-state-error {
    margin-left: 30px;
  }
}

.application-editable-row-grid {
  display: flex;
  justify-content: flex-start;

  .react-select-container {
    min-width: 180px;
  }
}

.application-details-grid {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 20px 0;

  .detail {
    margin-top: 5px;
    font-size: $subtitle;
    color: $primary-color;
  }
}

.add-task-button,
.add-note-button {
  margin-bottom: 10px;
  padding: 5px 15px;
  font: $text $font-medium;
  border-radius: 5px;
}
.add-document-button {
  margin-bottom: 10px;
  padding: 5px;
  font: $text $font-medium;
  border-radius: 5px;
}

.blockers-title {
  margin-bottom: 7px;
  font: $subtitle $font-medium;
  color: $secondary-color;
}

.current-business-address-title {
  margin: 10px 0;
  font: $subtitle $font-medium;
  color: $primary-color;
}

.current-business-address,
.client-reference,
.application-comment {
  display: grid;
  grid-template-columns: 150px auto;
  margin: 10px 0;

  &__label {
    padding-top: 10px;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &--button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 5px;
      color: white;
      background-color: #003a78;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      
      & > span {
        font-size: 18px;
        color: white;
      }
    }

    & > div[contenteditable] {
      outline: none;
      background-color: #ffffff;
      border-radius: 0.4rem;
      padding: 10px 15px 10px 0;
      flex-grow: 1;
      border: 0.063rem solid transparent;
      transition: all .3s;
    }

    & > div[contentEditable*="true"] {
      border-color: #003a78;
      background-color: #e7ecf2;
      padding-left: 10px;
    }
  }
}

.guideline {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px 15px;
  color: $field-color;
  background-color: $border-color;
  border-radius: 10px;
}

.guideline:last-child {
  margin-bottom: 0;
}

.view-application-question,
.view-application-answer {
  margin: 10px 0;
  color: $field-color;
}

.view-application-answer {
  color: $primary-color;
}

.application-filter-modal .form-title {
  width: 175px;
}

.accordion-body-container.application-active-accordion-scroll.active-accordion {
  max-height: 60vh;
  overflow: auto !important;
}

.view-application-accordion-description {
  margin-left: 2px;
  color: $primary-color;
  overflow: hidden;
}

.note-container {
  position: relative;
  display: flex;
}

.read-more-or-less {
  position: absolute;
  bottom: 1px;
  right: 0;
  padding: 0 0 2px 2px;
  font: $text $font-medium;
  color: $primary-color;
  text-decoration: underline;
  background-color: #fff;
  cursor: pointer;
}

.read-more-ellipsis {
  position: absolute;
  bottom: 1px;
  right: 62px;
  font: $subtitle $font-regular;
  color: $primary-color;
  background-color: #fff;
}

.view-application-accordion-dropdown .menu-name {
  font-size: $subtitle !important;
}

.fetch-report-modal {
  width: 500px;
}

.fetch-report-popup-container {
  display: grid;
  grid-template-columns: 150px minmax(200px, 400px);
  gap: 10px;
  align-items: center;
  font-size: $subtitle;

  span {
    color: $primary-color;
  }
}

.view-application-status-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .react-select-container {
  min-width: 180px;
}
}

.react-select--is-disabled.view-application-select.react-select-container .react-select__control{
  padding: 2px 5px;
}

.view-application-select .react-select-container .react-select__control.react-select__control--is-disabled .react-select__single-value {
    font: $subtitle $font-regular;
    color: $primary-color;
  }


.react-select-container.view-application-limit-type-disabled .react-select__control--is-disabled {
  padding: 0;
  .react-select__single-value {
    font: $subtitle $font-regular;
    color: $primary-color;
  }
}
  
.react-select-container.view-application-approval-type-disabled .react-select__control--is-disabled {
  padding: 0;
    .react-select__single-value {
    font: $subtitle $font-regular;
    color: $primary-color;
  }
}

.react-select-container .react-select__control .react-select__value-container {
  padding: 0;
}

.view-application-disabled-datepicker {
  padding: 0;
  border: none;

  input {
    font: $subtitle $font-regular;
    color: $primary-color
  }
}